import startDate from '../helpers/start-date'
import dayjs from 'dayjs'
import { monthYear, year, yearMonthDay } from '@/helpers/dateFormats'

export function makePeriod(unit, start, fromInit = null, toInit = null) {
  if (unit === undefined) {
    throw new Error(`unit required`)
  } else if (unit !== 'month' && unit !== 'year') {
    throw new Error(`unit must be equal to 'month' or 'year'`)
  }
  let from = startDate()
  let to = dayjs().startOf(unit)
  let diff = to.diff(from, unit)
  if (fromInit && toInit) {
    from = dayjs(fromInit)
    to = dayjs(toInit)
    diff = to.diff(from, unit) + 1
  }

  let period = []
  for (let i = 0; i <= diff; i++) {
    let date = to.subtract(i, unit).startOf('month')
    if (!start) {
      date = date.endOf('month')
    }
    let dateOption = {
      label: date.format(unit === 'month' ? monthYear : year),
      code: date.format(unit === 'month' ? yearMonthDay : year)
    }
    period.push(dateOption)
  }

  return period
}
